import React, { useEffect, useState } from 'react'

import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import RoadmapImage from '../assets/images/roadmap/road-map-bg.png'

import BuyImage from '../assets/images/buy.png'

import Roadmap01 from '../assets/images/roadmap/roadmap-q1.png'
import Roadmap02 from '../assets/images/roadmap/roadmap-q2.png'
import Roadmap03 from '../assets/images/roadmap/roadmap-q3.png'
import Roadmap04 from '../assets/images/roadmap/roadmap-q4.png'
import Roadmap05 from '../assets/images/roadmap/roadmap-q5.png'


const Home = () => {

    return (
        <>
            <div className="landing-page-main">
                <NavBar />
                <div className="main-hero">
                    <div className="container">
                        <div className="main-road-map-image">
                            <img src={RoadmapImage} alt="" />
                        </div>
                        <div className="main-roadmap-container">
                            <div className="roadmap-single-row single-01">
                                <img src={Roadmap01} alt="" />
                                <div id="animated-buy" class="animated bounce">
                                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x241b8e70e25495bb347b3e267ad2e27dcc3b2506" target="_blank"><img src={BuyImage} alt="" /></a>
                                </div>
                            </div>
                            <div className="roadmap-single-row">
                                <img src={Roadmap02} alt="" />
                                <img src={Roadmap03} alt="" />
                            </div>
                            <div className="roadmap-single-row">
                                <img src={Roadmap04} alt="" />
                                <img src={Roadmap05} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
                {/* <ScrollTop /> */}
            </div>

        </>
    )
}

export default Home
